/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react';
// Components
import RootWrapper from './src/components/RootWrapper';
import BrowserPageWrapper from './src/components/PageWrapper';
import {StyledEngineProvider} from '@mui/material/styles';

/*************************************************
 Wraps all pages of our app in our DonutStateProviderComponent (declared below)
 which contains our UserContext provider.
 https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 **************************************************/
export const wrapRootElement = ({element}) => {
  return (
    <StyledEngineProvider injectFirst>
      <RootWrapper>{element}</RootWrapper>
    </StyledEngineProvider>
  );
};

/*************************************************
 Wraps all pages of our app in our custom PageWrapper component.
 https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
**************************************************/
export const wrapPageElement = ({element, props}) => (
  <BrowserPageWrapper {...props}>{element}</BrowserPageWrapper>
);
