import React from 'react';
// Redux
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from 'redux/store';

const RootWrapper: React.FC<React.PropsWithChildren<any>> = ({children}) => {
  // if (!isBrowser()) {
  //   return <Provider store={store}>{children}</Provider>;
  // } else {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
  // }
};

export default RootWrapper;
