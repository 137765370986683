import {combineReducers} from '@reduxjs/toolkit';
// Reducers
import themeReducer from 'redux/features/themeSlice';
import adminUserReducer from 'redux/features/adminUserSlice';
import fulfillmentsReducer from 'redux/features/fulfillmentsSlice';
import modalReducer from 'redux/features/modalSlice';
import {apiSlice} from 'redux/features/apiSlice';

const rootReducer = combineReducers({
  theme: themeReducer,
  adminUser: adminUserReducer,
  fulfillments: fulfillmentsReducer,
  modalIsOpen: modalReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default rootReducer;
