import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AdminUserLoginData} from 'types/admin-hub.controllers';

export type AdminUserState = {
  id?: string;
  authToken?: string;
  image?: string;
  role?: string;
  name?: string;
};

const initialState: AdminUserState = {
  id: undefined,
  authToken: undefined,
  image: undefined,
  role: undefined,
  name: undefined,
};

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    setAdminUser(state, action: PayloadAction<AdminUserLoginData>) {
      return action.payload;
    },
    clearAdminUser() {
      return initialState;
    },
  },
});

// createSlice creates actions for us from our reducer defs
export const {setAdminUser, clearAdminUser} = adminUserSlice.actions;
export default adminUserSlice.reducer;
