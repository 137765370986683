import React, {PropsWithChildren} from 'react';
// Global Styles
import 'styles/global/global.css';
import 'styles/global/index.scss';
// Components

const PageWrapper: React.FC<PropsWithChildren<React.ReactNode>> = ({
  children,
}) => {
  return <>{children}</>;
};

export default PageWrapper;
