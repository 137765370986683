import {createApi} from '@reduxjs/toolkit/query/react';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
  AxiosResponse,
} from 'axios';

import {store} from 'redux/store';

import {BaseQueryFn} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  AddMilestoneReqBody,
  AddShippingFromAddressReqBody,
  AddUserReqBody,
  AdminUserLoginData,
  ConfirmFulfillmentReqBody,
  CreateShippingLabelAndFulfillReqBody,
  GenericAPIResponse,
  GetAllUsersFields,
  GetShippingRateReqBody,
  ParticipantDetailsReqParams,
  StrictErrorAPIResponse,
  StrictSuccessAPIResponse,
  UpdateMilestoneReqBody,
} from 'types/admin-hub.controllers';
import {
  AdminUser,
  IFulfillment,
  IParticipant,
  ISetting,
  Milestone,
} from 'types/admin-hub.models';
import {Messages, Rate} from 'types/shippo';
import {clearAdminUser} from 'redux/features/adminUserSlice';

export interface ParticipantDetailsResponse extends IParticipant {
  referrals: ReadonlyArray<
    Pick<
      IParticipant,
      '_id' | 'referralId' | 'referralSource' | 'phone' | 'email' | 'createdAt'
    >
  >;
  fulfillments: ReadonlyArray<Omit<IFulfillment, 'participant'>>;
}

const axiosBaseQuery = (
  {baseUrl}: {baseUrl?: string} = {baseUrl: ''}
): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headerOverrides?: RawAxiosRequestHeaders;
  },
  GenericAPIResponse<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  GenericAPIResponse<any> // eslint-disable-line @typescript-eslint/no-explicit-any
> => {
  return async (args) => {
    const {url, method, data, params, headerOverrides} = args;
    const headers: RawAxiosRequestHeaders = headerOverrides || {
      'Content-Type': 'application/json',
    };
    try {
      const result: AxiosResponse<GenericAPIResponse<any>> =
        await axios.request({
          url: baseUrl + url,
          withCredentials: true,
          method,
          data,
          params,
          headers,
        });
      // TODO: Remember to check for authError when we new auth flow implemented
      if (result.data.error) {
        return {error: result.data};
      } else {
        return {data: result.data};
      }
    } catch (axiosError) {
      const err = axiosError as AxiosError<GenericAPIResponse<any>>;
      if (err.response?.data?.authError) {
        store.dispatch(clearAdminUser());
      }
      return {
        error: {
          data: err.response?.data?.data,
          error: 1,
          authError: err.response?.data?.authError || 0,
          msg: err.response?.data?.msg || err.message,
        },
      };
    }
  };
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.GATSBY_AMBASSADOR_API_BASEURL,
  }),
  tagTypes: [
    'Fulfillments',
    'Participants',
    'Milestones',
    'Reward',
    'Settings',
  ],
  endpoints(builder) {
    return {
      adminUserLogin: builder.query<
        GenericAPIResponse<AdminUserLoginData>,
        string
      >({
        query(tokenId) {
          return {
            url: '/admin/login',
            method: 'POST',
            data: {tokenId},
          };
        },
      }),
      healthCheck: builder.query<any, void>({
        query() {
          return {
            url: '/health',
            method: 'GET',
          };
        },
      }),
      adminUserLogout: builder.query<any, void>({
        query() {
          return {
            url: '/admin/logout',
            method: 'GET',
          };
        },
      }),
      getShippingSettings: builder.query<
        GenericAPIResponse<ISetting | null>,
        void
      >({
        query() {
          return {
            url: '/settings/shipping',
            method: 'GET',
          };
        },
        providesTags: ['Settings'],
      }),
      addShippingSettings: builder.mutation<
        GenericAPIResponse<Record<string, never>>,
        AddShippingFromAddressReqBody
      >({
        query(shippingData) {
          return {
            url: '/settings/shipping',
            method: 'PUT',
            data: shippingData,
          };
        },
        invalidatesTags: ['Settings'],
      }),
      getAllUsers: builder.query<
        GenericAPIResponse<Array<GetAllUsersFields>>,
        void
      >({
        query() {
          return {
            url: '/admin/all',
            method: 'GET',
          };
        },
      }),
      addAdminUser: builder.query<
        GenericAPIResponse<AdminUser>,
        AddUserReqBody
      >({
        query(userData) {
          return {
            url: '/admin',
            method: 'PUT',
            data: userData,
          };
        },
      }),
      deleteAdminUser: builder.query({
        query(id) {
          return {
            url: '/admin/' + id,
            method: 'DELETE',
          };
        },
      }),
      getShippingRates: builder.query<
        StrictSuccessAPIResponse<Rate> | StrictErrorAPIResponse<Messages>,
        GetShippingRateReqBody
      >({
        query(body) {
          return {
            url: '/fulfillment/rate/',
            method: 'POST',
            data: body,
          };
        },
      }),
      createShippingLabelAndFulfill: builder.mutation<
        | StrictSuccessAPIResponse<{shippingLabel: string; trackingUrl: string}>
        | StrictErrorAPIResponse<Record<string, never>>,
        CreateShippingLabelAndFulfillReqBody
      >({
        query(body) {
          return {
            url: '/fulfillment/shipping-label-fulfill',
            method: 'PATCH',
            data: body,
          };
        },
      }),
      confirmFulfillment: builder.mutation<
        GenericAPIResponse<{id: string}>,
        ConfirmFulfillmentReqBody
      >({
        query(body) {
          return {
            url: '/fulfillment/confirm/',
            method: 'PATCH',
            data: body,
          };
        },
        invalidatesTags: ['Fulfillments'],
      }),
      getAllFulfillments: builder.query<
        GenericAPIResponse<Array<IFulfillment>>,
        void
      >({
        query() {
          return {
            url: '/fulfillment/all',
            method: 'GET',
          };
        },
        providesTags: ['Fulfillments'],
      }),
      getAllParticipants: builder.query<
        GenericAPIResponse<Array<IParticipant>>,
        void
      >({
        query() {
          return {
            url: '/participant/all',
            method: 'GET',
          };
        },
        providesTags: ['Participants'],
      }),
      getParticipantDetails: builder.query<
        GenericAPIResponse<ParticipantDetailsResponse>,
        ParticipantDetailsReqParams
      >({
        query({participant}) {
          return {
            url: '/participant/' + participant,
            method: 'GET',
          };
        },
      }),
      deactivateParticipants: builder.mutation<
        GenericAPIResponse<Record<string, never>>,
        {participants: Array<string>}
      >({
        query(data) {
          return {
            url: '/participants/deactivate',
            method: 'PATCH',
            data,
          };
        },
        invalidatesTags: ['Participants'],
      }),
      getAllMilestones: builder.query<
        GenericAPIResponse<ReadonlyArray<Milestone>>,
        void
      >({
        query() {
          return {
            url: '/milestone/all',
            method: 'GET',
          };
        },
        providesTags: ['Milestones'],
      }),
      getMilestone: builder.query<GenericAPIResponse<Milestone>, {id: string}>({
        query(data) {
          return {
            url: '/milestone/' + data.id,
            method: 'GET',
          };
        },
        providesTags: ['Milestones'],
      }),
      addMilestone: builder.query<
        GenericAPIResponse<Milestone>,
        AddMilestoneReqBody
      >({
        query(milestoneData) {
          return {
            url: '/milestone',
            method: 'PUT',
            data: milestoneData,
          };
        },
      }),
      updateMilestone: builder.mutation<
        GenericAPIResponse<Record<string, unknown>>,
        UpdateMilestoneReqBody
      >({
        query(milestoneData) {
          return {
            url: '/milestone',
            method: 'POST',
            data: milestoneData,
          };
        },
        invalidatesTags: ['Milestones'],
      }),
      deleteMilestone: builder.query<
        GenericAPIResponse<Record<string, unknown>>,
        string
      >({
        query(id) {
          return {
            url: '/milestone/' + id,
            method: 'DELETE',
          };
        },
      }),
      addReward: builder.mutation<GenericAPIResponse<Milestone>, FormData>({
        query(formData) {
          return {
            url: '/milestone/reward',
            method: 'PUT',
            data: formData,
            headerOverrides: {
              'Content-Type': 'multipart/form-data',
            },
          };
        },
        invalidatesTags: ['Milestones'],
      }),
      updateReward: builder.mutation<GenericAPIResponse<Milestone>, FormData>({
        query(formData) {
          return {
            url: '/milestone/reward',
            method: 'POST',
            data: formData,
            headerOverrides: {
              'Content-Type': 'multipart/form-data',
            },
          };
        },
        invalidatesTags: ['Milestones'],
      }),
      removeReward: builder.mutation<
        GenericAPIResponse<Record<string, unknown>>,
        {rewardId: string; milestoneId: string}
      >({
        query({rewardId, milestoneId}) {
          return {
            url: `/milestone/reward/${milestoneId}/${rewardId}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: ['Milestones'],
      }),
    };
  },
});

export const {
  // Health Check
  useHealthCheckQuery,
  // Admin Users
  useGetAllUsersQuery,
  useLazyAddAdminUserQuery,
  useLazyDeleteAdminUserQuery,
  useLazyAdminUserLoginQuery,
  useLazyAdminUserLogoutQuery,
  // Settings
  useGetShippingSettingsQuery,
  useAddShippingSettingsMutation,
  // Fulfillment
  useConfirmFulfillmentMutation,
  useLazyGetShippingRatesQuery,
  useCreateShippingLabelAndFulfillMutation,
  useGetAllFulfillmentsQuery,
  // Participants
  useGetAllParticipantsQuery,
  useDeactivateParticipantsMutation,
  useGetParticipantDetailsQuery,
  // Milestones
  useLazyAddMilestoneQuery,
  useUpdateMilestoneMutation,
  useLazyDeleteMilestoneQuery,
  useGetMilestoneQuery,
  useGetAllMilestonesQuery,
  // Rewards
  useAddRewardMutation,
  useUpdateRewardMutation,
  useRemoveRewardMutation,
} = apiSlice;
