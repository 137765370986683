import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {IFulfillment} from 'types/admin-hub.models';

const initialState = [] as Array<IFulfillment>;

const fulfillmentSlice = createSlice({
  name: 'fulfillments',
  initialState,
  reducers: {
    setFulfillments(state, action: PayloadAction<Array<IFulfillment>>) {
      return action.payload;
    },
    // setFulfillmentStatus(
    //   state,
    //   action: PayloadAction<{id: string; status: FulfillmentStatus}>
    // ) {
    //   const fulfillment = state.find((f) => f._id === action.payload.id);
    //   if (fulfillment) {
    //     fulfillment.status = action.payload.status;
    //   }
    // },
  },
});

// createSlice creates actions for us from our reducer defs
export const {setFulfillments} = fulfillmentSlice.actions;
export default fulfillmentSlice.reducer;
