import {createSlice} from '@reduxjs/toolkit';

const initialState = false;

const modalSlice = createSlice({
  name: 'modalIsOpen',
  initialState,
  reducers: {
    openModal() {
      return true;
    },
    closeModal() {
      return false;
    },
  },
});

// createSlice creates actions for us from our reducer defs
export const {openModal, closeModal} = modalSlice.actions;
export default modalSlice.reducer;
